<template>
    <div class="register">
        <bread-nav></bread-nav>
        <h2>服务协议</h2>
        <div class="form" v-if="agreement" v-html="agreement.content">
            <!-- {{ agreement.content }} -->
        </div>
        <span class="go"><a href="/web/register" style="color:#fff">返回注册</a></span>
    </div>
</template>
<script>
import security from "../../member/security/security"
export default {
    name: "security",
    mixins: [security]
}
</script>
<script>
import { getRegisiterAggrement, registerConfig, registerMobileCode, getRegisterReward, registerMobile } from "@/api/auth/register"
import { captcha } from "@/api/website"
import BreadNav from "@/layout/components/bread-nav.vue"
import PasswordStrength from "@/components/PasswordStrength.vue"

export default {
    name: "register",
    components: { BreadNav, PasswordStrength },
    data() {
        var checkPassValidata = (rule, value, callback) => {
            if (value === "") {
                callback(new Error("请再次输入密码"))
            } else if (value !== this.registerForm.password) {
                callback(new Error("两次输入密码不一致!"))
            } else {
                callback()
            }
        }
        let self = this
        var passwordValidata = function (rule, value, callback) {
            let regConfig = self.registerConfig
            if (!value) {
                return callback(new Error("请输入密码"))
            } else {
                if (regConfig.pwd_len > 0) {
                    if (value.length < regConfig.pwd_len) {
                        return callback(new Error("密码长度不能小于" + regConfig.pwd_len + "位"))
                    } else {
                        callback()
                    }
                } else {
                    if (regConfig.pwd_complexity != "") {
                        let passwordErrorMsg = "密码需包含",
                            reg = ""
                        if (regConfig.pwd_complexity.indexOf("number") != -1) {
                            reg += "(?=.*?[0-9])"
                            passwordErrorMsg += "数字"
                        } else if (regConfig.pwd_complexity.indexOf("letter") != -1) {
                            reg += "(?=.*?[a-z])"
                            passwordErrorMsg += "、小写字母"
                        } else if (regConfig.pwd_complexity.indexOf("upper_case") != -1) {
                            reg += "(?=.*?[A-Z])"
                            passwordErrorMsg += "、大写字母"
                        } else if (regConfig.pwd_complexity.indexOf("symbol") != -1) {
                            reg += "(?=.*?[#?!@$%^&*-])"
                            passwordErrorMsg += "、特殊字符"
                        } else {
                            reg += ""
                            passwordErrorMsg += ""
                        }

                        if (reg.test(value)) {
                            return callback(new Error(passwordErrorMsg))
                        } else {
                            callback()
                        }
                    }
                }
            }
        }

        var isMobile = (rule, value, callback) => {
            if (!value) {
                return callback(new Error("手机号不能为空"))
            } else {
                const reg = /^1[3|4|5|6|7|8|9][0-9]{9}$/

                if (reg.test(value)) {
                    callback()
                } else {
                    callback(new Error("请输入正确的手机号"))
                }
            }
        }
        return {
            password: "",
            dis: true,
            registerForm: {
                username: "",
                password: "",
                checkPass: "",
                code: "",
                mobile: "",
                vercode: "",
                dynacode: "",
                key: ""
            },
            registerRules: {
                mobile: [
                    {
                        required: true,
                        message: "请输入手机号",
                        trigger: "blur"
                    }
                ],
                code: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur"
                    }
                ]
            },
            mobileRules: {
                mobile: [
                    {
                        required: true,
                        validator: isMobile,
                        trigger: "blur"
                    }
                ],
                vercode: [
                    {
                        required: true,
                        message: "请输入验证码",
                        trigger: "blur"
                    }
                ],
                dynacode: [
                    {
                        required: true,
                        message: "请输入短信动态码",
                        trigger: "blur"
                    }
                ],
                password: [
                    {
                        required: true,
                        message: "请输入密码",
                        trigger: "blur"
                    }
                ]
            },
            dynacodeData: {
                seconds: 120,
                timer: null,
                codeText: "获取动态码",
                isSend: false
            }, // 动态码
            ischecked: false,
            agreement: "",
            aggrementVisible: false,
            captcha: {
                // 验证码
                id: "",
                img: ""
            },
            registerConfig: {
                register: ""
            },
            activeName: "first", // tab切换
            reward: null,
            is_show: false,
            bgWidth: "",
            bgHeight: ""
        }
    },
    created() {
        this.getCode()
        this.regisiterAggrement()
        this.getRegisterConfig()
        this.getRegisterReward()
        this.bgWidth = document.documentElement.clientWidth + "px"
        this.bgHeight = document.documentElement.clientHeight + "px"
    },
    methods: {
        closeRewardPopup(type) {
            this.is_show = false

            switch (type) {
                case "point":
                    this.$router.push("/member/my_point")
                    break
                case "balance":
                    this.$router.push("/member/account")
                    break
                case "growth":
                    this.$router.push("/member/index")
                    break
                case "coupon":
                    this.$router.push("/member/my_coupon")
                    break
                default:
                    this.$router.push("/member/index")
                    this.is_show = false
            }
        },
        getRegisterReward() {
            getRegisterReward()
                .then((res) => {
                    if (res.code >= 0) {
                        let data = res.data
                        if (data.is_use == 1 && (data.value.point > 0 || data.value.balance > 0 || data.value.growth > 0 || data.value.coupon_list.length > 0)) {
                            this.reward = data.value
                        }
                    }
                    console.log(res, "getRegisterReward")
                })
                .catch((err) => {
                    console.log(err, "getRegisterReward")
                })
        },
        sendMobileCode(formName) {
            if (this.dynacodeData.seconds != 120) return
            this.$refs[formName].clearValidate("dynacode")

            this.$refs[formName].validateField("mobile", (valid) => {
                if (!valid) {
                    registerMobileCode({
                        mobile: this.registerForm.mobile,
                        captcha_id: this.captcha.id,
                        captcha_code: this.registerForm.vercode
                    })
                        .then((res) => {
                            if (res.code >= 0) {
                                this.registerForm.key = res.data.key
                                if (this.dynacodeData.seconds == 120) {
                                    let timer = setInterval(() => {
                                        this.disabled = true
                                        this.dynacodeData.seconds--
                                        this.dynacodeData.codeText = this.dynacodeData.seconds + "s后可重新获取"
                                        if (this.dynacodeData.seconds <= 0) {
                                            this.disabled = false
                                            this.dynacodeData.codeText = "获取验证码"
                                            this.dynacodeData.seconds = 120
                                            clearTimeout(timer)
                                        }
                                    }, 1000)
                                }
                            } else {
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err.message)
                        })
                } else {
                    return false
                }
            })
        },
        handleClick(tab, event) {
            if (this.activeName == "first") {
                this.loginMode = "account"
            } else {
                this.loginMode = "mobile"
            }
        },
        check() {
            this.ischecked = !this.ischecked
        },
        toLogin() {
            this.$router.push("/login")
        },
        //  获取注册配置
        getRegisterConfig() {
            registerConfig()
                .then((res) => {
                    if (res.code >= 0) {
                        this.registerConfig = res.data.value
                        if (this.registerConfig.register == "") {
                            this.$message({
                                message: "平台未启用注册",
                                type: "warning",
                                duration: 2000,
                                onClose: () => {
                                    this.$router.push("/")
                                }
                            })
                        }
                    }
                })
                .catch((err) => {
                    console.log(err.message)
                })
        },
        // 账号密码注册
        register(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    // if (!this.ischecked) {
                    // 	return this.$message({
                    // 		message: '请先阅读协议并勾选',
                    // 		type: 'warning'
                    // 	});
                    // }
                    var data = {
                        username: this.registerForm.username.trim(),
                        password: this.registerForm.password
                    }
                    if (this.captcha.id != "") {
                        data.captcha_id = this.captcha.id
                        data.captcha_code = this.registerForm.code
                    }
                    this.$store
                        .dispatch("member/register_token", data)
                        .then((res) => {
                            if (res.code >= 0) {
                                if (this.reward) {
                                    this.is_show = true
                                } else {
                                    this.$router.push("/member/index")
                                }
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err.message)
                            this.getCode()
                        })
                } else {
                    return false
                }
            })
        },
        // 手机号注册
        registerMobile() {
            this.$refs.mobileRuleForm.validate((valid) => {
                if (valid) {
                    // if (!this.ischecked) {
                    //     return this.$message({
                    //         message: "请先阅读协议并勾选",
                    //         type: "warning"
                    //     })
                    // }
                    var data = {
                        mobile: this.registerForm.mobile,
                        key: this.registerForm.key,
                        code: this.registerForm.dynacode,
                        password: this.registerForm.password
                    }
                    if (this.captcha.id != "") {
                        data.captcha_id = this.captcha.id
                        data.captcha_code = this.registerForm.code
                    }
                    this.$store
                        .dispatch("member/registerMobile_token", data)
                        // console.log(data) //password能获取到
                        .then((res) => {
                            if (res.code >= 0) {
                                if (this.reward) {
                                    this.is_show = true
                                    console.log(res)
                                } else {
                                    this.$router.push("/login")
                                }
                            }
                        })
                        .catch((err) => {
                            this.$message.error(err.message)
                            this.getCode()
                        })
                } else {
                    return false
                }
            })
        },
        aggrementClose() {
            this.aggrementVisible = false
        },
        // 获取协议
        regisiterAggrement() {
            getRegisiterAggrement()
                .then((res) => {
                    if (res.code >= 0) {
                        this.agreement = res.data
                        // console.log(this.agreement.content)
                    }
                })
                .catch((err) => {
                    console.log(err.message)
                })
        },
        getAggrement() {
            this.aggrementVisible = true
        },
        // 获取验证码
        getCode() {
            captcha({
                captcha_id: "this.captcha.id"
            })
                .then((res) => {
                    if (res.code >= 0) {
                        this.captcha = res.data
                        this.captcha.img = this.captcha.img.replace(/\r\n/g, "")
                    }
                })
                .catch((err) => {
                    this.$message.error(err.message)
                })
        }
    }
}
</script>

<style lang="scss" scoped>
.register {
    padding-top: 30px;
}
h2 {
    width: 625px;
    font-size: 24px;
    color: $ns-text-color-black;
    padding: 10px 0;
    border-bottom: 1px solid $base-color-gray;
    text-align: center;
    margin: 50px auto 0;
    position: relative;

    &:after {
        width: 80px;
        height: 1px;
        background-color: $base-color;
        content: "";
        display: block;
        position: absolute;
        left: 50%;
        margin-left: -40px;
        bottom: 0px;
    }
}

.form {
    width: 800px;
    margin: 30px auto;

    .el-form-item {
        font-size: $ns-font-size-lg;

        .input-icon {
            width: 28px;
            height: 28px;

            img {
                width: 100%;
                height: 100%;
            }
        }
        .el-button {
            background-color: $base-color;
            color: #333;
            border-radius: 0;
            background-color: #e9e9e9;
        }
    }

    .el-button--primary {
        background-color: $base-color;
        color: #ffffff;
        border-radius: 0;
        width: 100%;
        font-size: 20px;
    }

    .vercode {
        width: 100%;

        .el-input-group__append {
            width: 100px !important;

            img {
                width: 100px;
            }
        }
    }
}
#showPasswordToggle {
    margin-left: -100px;
}

#inputValue {
    width: 240px;
    margin-left: 20px;
    padding-left: 10px;
    border-radius: 3px;
}
.input_span {
    position: fixed;
    left: 50%;
    margin-left: 270px;
    top: 493px;
}
.input_span span {
    display: inline-block;
    width: 20px;
    height: 10px;
    // background: #eee;
    line-height: 20px;
}
.password {
    // float: left;
}
.password-icon {
    float: left;
    // margin-left: 10px;
    width: 69px;
    text-align: center;
    background-color: #f5f7fa;
    border: 1px solid #dcdfe6;
    border-radius: 4px;
    img {
        width: 28px;
        height: 28px;
    }
}
.password-text {
    el-input {
        width: 341px;
        height: 40px;
    }
}
.password-strength {
    margin-top: 10px;
}
.go {
    padding: 10px 20px;
    background-color: #ed6030;
    margin-left: 46%;
    font-size: 20px;
    width: 100%;
    border-radius: 0px;
    color: #fff;
    // margin: 0px auto;
    margin-bottom: 20px;
}
</style>
